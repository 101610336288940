//设备形态转换申请
<template>
  <Page ref="page" v-loading="loading" :showReturn="!isCreate">
    <div>
      <el-form ref="form" label-width="140px" :model="params" :rules="rules">
        <div class="cm-flex-line">
          <el-form-item label="申请人：" prop="proposerCode">
            <SelectProposer
              :code.sync="params.proposerCode"
              :name.sync="params.proposerName"
              @change="proposerChange"
              :disabled="!isCreate"
            />
          </el-form-item>
          <el-form-item label="申请部门：" prop="applyDepartmentCode">
            <SelectDepartment
              ref="SelectDepartment"
              :code.sync="params.applyDepartmentCode"
              :name.sync="params.applyDepartmentName"
              disabled
            />
          </el-form-item>
          <el-form-item label="日期：" prop="orderTime">
            <el-date-picker
              style="width: 100%"
              v-model="params.orderTime"
              placeholder="请选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="{
                disabledDate(time) {
                  return time.getTime() > Date.now()
                }
              }"
            />
          </el-form-item>
        </div>
        <div class="cm-flex-line">
          <el-form-item label="入库类别：" prop="inStorageCode">
            <SelectWarehouseType
              :code.sync="params.inStorageCode"
              :name.sync="params.inStorageName"
              :type="1"
            />
          </el-form-item>
          <el-form-item label="出库类别：" prop="outStorageCode">
            <SelectWarehouseType
              :code.sync="params.outStorageCode"
              :name.sync="params.outStorageName"
              :type="0"
            />
          </el-form-item>
          <el-form-item label="是否可本人转换：" prop="selfConvertType">
            <el-select v-model="params.selfConvertType" style="width: 100%">
              <el-option label="否" :value="0"></el-option>
              <el-option label="是" :value="1"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item label="申请理由：" prop="applyReason">
          <el-input
            v-model="params.applyReason"
            placeholder="请输入申请理由"
            maxlength="200"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input
            v-model="params.remark"
            placeholder="请输入备注"
            type="textarea"
            maxlength="255"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item prop="fileList" label="附件：">
          <UploadFile
            ref="UploadFile"
            v-model="params.fileList"
            :showAccept="false"
            :limit="3"
            multiple
            :preAppendixAddr="params && params.attachmentUrl"
          />
        </el-form-item>
        <el-divider content-position="left">转换物品清单</el-divider>
        <div style="display: flex; justify-content: end; margin-bottom: 10px">
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="addEvent()"
            v-if="params.type == 2"
          >
            添加
          </el-button>
        </div>
        <div>
          <el-form-item label="转换方式：" label-width="110px">
            <el-select v-model="params.type" @change="changeSelect">
              <el-option label="一对一" :value="1"></el-option>
              <el-option label="多对一" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <div
            class="cm-flex-line"
            v-for="(item, $index) in params.detailUpdateInputs"
            :key="$index"
            style="padding-left: 110px"
          >
            <div
              style="
                border: 1px dashed #ddd;
                border-radius: 10px;
                padding: 5px;
                margin-bottom: 10px;
              "
            >
              <el-row>
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="3">
                  <el-form-item
                    label="转换前料号："
                    label-width="120px"
                    :prop="'detailUpdateInputs.' + $index + '.code'"
                    :rules="rules.code"
                  >
                    <SelectGoodsCode
                      :code.sync="params.detailUpdateInputs[$index].code"
                      :name.sync="params.detailUpdateInputs[$index].name"
                      :bserial.sync="params.detailUpdateInputs[$index].bserial"
                      style="min-width: 90px"
                      @change="
                        data => {
                          if (data.bserial) {
                            params.detailUpdateInputs[$index].num = 1
                          }
                        }
                      "
                    />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="3">
                  <el-form-item
                    label="转换前物品名称："
                    label-width="140px"
                    :prop="'detailUpdateInputs.' + $index + '.name'"
                    :rules="rules.name"
                  >
                    <SelectGoodsName
                      :code.sync="params.detailUpdateInputs[$index].code"
                      :name.sync="params.detailUpdateInputs[$index].name"
                      :bserial.sync="params.detailUpdateInputs[$index].bserial"
                      style="min-width: 90px"
                      @change="
                        data => {
                          if (data.bserial) {
                            params.detailUpdateInputs[$index].num = 1
                          }
                        }
                      "
                    />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="3">
                  <el-form-item
                    label="所属仓库："
                    label-width="120px"
                    :prop="'detailUpdateInputs.' + $index + '.warehouseCode'"
                    :rules="rules.warehouseCode"
                  >
                    <SelectWarehouse
                      :code.sync="
                        params.detailUpdateInputs[$index].warehouseCode
                      "
                      :name.sync="
                        params.detailUpdateInputs[$index].warehouseName
                      "
                    />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="3">
                  <el-form-item
                    label="货位："
                    label-width="120px"
                    :prop="'detailUpdateInputs.' + $index + '.storageCode'"
                    :rules="rules.storageCode"
                  >
                    <SelectGoodsLocation
                      :ref="'SelectGoodsLocation' + $index"
                      :code.sync="params.detailUpdateInputs[$index].storageCode"
                      :name.sync="params.detailUpdateInputs[$index].storageName"
                      :params="{
                        inventoryCode: params.detailUpdateInputs[$index].code,
                        warehouseCode:
                          params.detailUpdateInputs[$index].warehouseCode
                      }"
                    />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="3">
                  <el-form-item
                    label="转换前物品sn："
                    label-width="120px"
                    :prop="'detailUpdateInputs.' + $index + '.oldSn'"
                  >
                    <SelectSn
                      :ref="'SelectSn' + $index"
                      :code.sync="params.detailUpdateInputs[$index].oldSn"
                      :name.sync="params.detailUpdateInputs[$index].oldSn"
                      :params="{
                        cInvCode: params.detailUpdateInputs[$index].code,
                        cWhCode:
                          params.detailUpdateInputs[$index].warehouseCode,
                        cPosCode: params.detailUpdateInputs[$index].storageCode,
                        bserial: params.detailUpdateInputs[$index].bserial
                      }"
                    />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="3">
                  <el-form-item
                    label="转换数量："
                    label-width="120px"
                    :prop="'detailUpdateInputs.' + $index + '.num'"
                    :rules="rules.num"
                  >
                    <el-input
                      v-model="params.detailUpdateInputs[$index].num"
                      placeholder="请输入"
                      :readonly="params.detailUpdateInputs[$index].bserial"
                      @input="
                        val =>
                          (params.detailUpdateInputs[$index].num =
                            $format.fmtNumber(val))
                      "
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div style="flex: inherit; width: 60px">
              <el-button
                v-if="params.type == 2 && params.detailUpdateInputs.length > 1"
                type="text"
                @click="deleteEvent($index)"
                style="margin-left: 20px"
              >
                删除
              </el-button>
            </div>
          </div>
          <div style="padding: 0 60px 0 110px">
            <div
              style="
                border: 1px dashed #ddd;
                border-radius: 10px;
                padding: 5px;
                margin-bottom: 10px;
              "
            >
              <el-row>
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="3">
                  <el-form-item
                    label="转换后料号："
                    label-width="120px"
                    :prop="'newInputs.code'"
                    :rules="rules.code"
                  >
                    <SelectGoodsCode
                      :code.sync="params.newInputs.code"
                      :name.sync="params.newInputs.name"
                      style="min-width: 90px"
                    />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="3">
                  <el-form-item
                    label="转换后物品名称："
                    label-width="140px"
                    :prop="'newInputs.name'"
                    :rules="rules.name"
                  >
                    <SelectGoodsName
                      :code.sync="params.newInputs.code"
                      :name.sync="params.newInputs.name"
                      style="min-width: 90px"
                    />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="3">
                  <el-form-item
                    label="所属仓库："
                    label-width="120px"
                    :prop="'newInputs.warehouseCode'"
                    :rules="rules.warehouseCode"
                  >
                    <SelectWarehouse
                      :code.sync="params.newInputs.warehouseCode"
                      :name.sync="params.newInputs.warehouseName"
                    />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="3">
                  <el-form-item
                    label="货位："
                    label-width="120px"
                    :prop="'newInputs.storageCode'"
                    :rules="rules.storageCode"
                  >
                    <SelectGoodsLocation
                      type="in"
                      ref="SelectGoodsLocation"
                      :code.sync="params.newInputs.storageCode"
                      :name.sync="params.newInputs.storageName"
                      :params="{
                        warehouseCode: params.newInputs.warehouseCode
                      }"
                    />
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="6">
                  <el-form-item
                    label="转换后物品sn："
                    label-width="120px"
                    :prop="'newInputs.newSn'"
                  >
                    <el-input
                      v-model="params.newInputs.newSn"
                      placeholder="请输入"
                      oninput="value=value.replace(/[\u4e00-\u9fa5|\s]/g, '')"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="3">
                  <el-form-item
                    label="转换数量："
                    label-width="120px"
                    :prop="'newInputs.num'"
                    :rules="rules.num"
                  >
                    <el-input
                      v-model="params.newInputs.num"
                      placeholder="请输入"
                      @input="
                        val => (params.newInputs.num = $format.fmtNumber(val))
                      "
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <FlowableStep
      orderType="5"
      :variables="variables"
      @initNode="setNodes"
      @refresh="setAuditors"
    ></FlowableStep>
    <template slot="bottom">
      <el-button
        type="primary"
        @click="submitEvent(1)"
        style="margin-right: 10px"
      >
        提交申请
      </el-button>
      <el-button type="info" @click="submitEvent(2)">保存草稿</el-button>
    </template>
  </Page>
</template>

<script>
const addItem = {
  type: 1,
  num: '',
  code: '',
  name: '',
  oldSn: '',
  storageCode: '',
  storageName: ''
}
import FlowableStep from '@/components/FlowableStep'
import applyMixins from '../../mixins/applyMixins'
export default {
  mixins: [applyMixins],
  components: { FlowableStep },
  data() {
    return {
      rules: {
        proposerCode: [{ required: true, message: '请选择申请人' }],
        applyDepartmentCode: [{ required: true, message: '请选择申请人部门' }],
        orderTime: [{ required: true, message: '请选择日期' }],
        inStorageCode: [{ required: true, message: '请选择入库类别' }],
        outStorageCode: [{ required: true, message: '请选择出库类别' }],
        selfConvertType: [{ required: true, message: '请选择是否可本人转换' }],
        applyReason: [{ required: true, message: '请输入申请理由' }],
        num: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value || Number(value) === 0) {
                callback('请输入非0整数')
              } else {
                callback()
              }
            }
          }
        ],
        code: [{ required: true, message: '请选择' }],
        name: [{ required: true, message: '请选择' }],
        warehouseCode: [{ required: true, message: '请选择' }],
        storageCode: [
          {
            required: false,
            validator: (rule, value, callback) => {
              try {
                let index = rule.field?.split('.')[1]
                if (index === 'storageCode') index = ''
                const $elem =
                  index === ''
                    ? this.$refs['SelectGoodsLocation' + index]
                    : this.$refs['SelectGoodsLocation' + index][0]
                const list = $elem?.list || []
                if (list.length > 0 && !value) {
                  callback('请选择货位')
                } else {
                  callback()
                }
              } catch (e) {
                console.log(e)
              }
            }
          }
        ]
      },
      params: {
        type: 1, //1是一对一 2是多对一
        selfConvertType: 1,
        inStorageCode: '109',
        inStorageName: '转换入库',
        outStorageCode: '205',
        outStorageName: '转换出库',
        detailUpdateInputs: [this.$utils.deepClone(addItem)],
        newInputs: { ...addItem, type: 2, newSn: '' },
        auditors: {}
      },
      nodes: [],
      variables: {}
    }
  },
  computed: {
    isCreate() {
      return this.$route.query.edit != 1
    }
  },
  mounted() {
    this.loading = true
    this.init()
    this.loading = false
  },
  methods: {
    async changeSelect(val) {
      try {
        if (val == 1 && this.params.detailUpdateInputs.length > 1) {
          await this.$confirm(
            `转换方式切换为一对一时，“现有料号”将被删除${
              this.params.detailUpdateInputs.length - 1
            }项，是否切换？`,
            '温馨提示'
          )
          this.params.detailUpdateInputs = [
            { ...this.params.detailUpdateInputs[0] }
          ]
        }
      } catch (e) {
        this.params.type = 2
      }
    },
    async init() {
      try {
        const { orderCode } = this.$route.query
        const res = await this.$api.apply.convertInfo({ orderCode })
        if (res) {
          const { orderResult, detailResults } = res
          let detailUpdateInputs = this.$utils
            .deepClone(detailResults)
            .splice(0, detailResults.length - 1)
          let newInputs =
            this.$utils.deepClone(detailResults)[detailResults.length - 1]
          let params = {
            ...orderResult,
            detailUpdateInputs,
            newInputs,
            auditors: {}
          }
          this.params = params
        }
      } catch (err) {
        console.log(err)
      }
    },
    addEvent() {
      this.params.detailUpdateInputs.push(addItem)
    },
    deleteEvent(index) {
      this.params.detailUpdateInputs = this.params.detailUpdateInputs.filter(
        (a, i) => i !== index
      )
    },
    setNodes(nodes) {
      this.nodes = nodes
    },
    setAuditors(auditors) {
      this.params.auditors = auditors
    },
    submitEvent(submit) {
      if (this.loading) return
      let params = this.$utils.deepClone(this.params)
      if (params.orderTime) {
        params.orderTime = params.orderTime + ' 00:00:00'
      }
      if (params.fileList?.length > 0) {
        params.attachmentUrl = params.fileList
          .map(a => {
            return `${a.url}?fileName=${a.name}`
          })
          .join(',')
        delete params.fileList
      }
      let detailUpdateInputs = params.detailUpdateInputs
      let newInputs = params.newInputs
      delete params.detailUpdateInputs
      delete params.newInputs
      detailUpdateInputs.push(newInputs)
      let newParams = {
        submit: submit == 1,
        detailUpdateInputs,
        updateInput: params
      }
      if (submit != 1) {
        try {
          this.loading = true
          this.$api.apply.convertSave(newParams).then(result => {
            this.$message.success('草稿保存成功')
          })
        } finally {
          this.loading = false
        }
      } else {
        if (this.nodes.length) {
          let flag = true
          for (let i = 0; i < this.nodes.length; i++) {
            if (
              this.nodes[i].assigneeType == 'SELF_SELECT' &&
              this.nodes[i].userType == 2 &&
              this.nodes[i].users.length == 0 &&
              !this.params?.auditors[this.nodes[i].id]?.length
            ) {
              flag = false
              return this.$message.warning('请选择审批人后提交')
            }
          }
          console.log('flag', flag)
          if (!flag) return
        }
        this.$refs['form'].validate(async valid => {
          if (valid) {
            try {
              if (this.params.detailUpdateInputs.length === 0) {
                this.$message.warning('请至少添加一条物品清单')
                return
              }
              if (
                this.params.type == 2 &&
                this.params.detailUpdateInputs.length < 2
              ) {
                this.$message.warning('转换方式多对一至少添加两条现有物品清单')
                return
              }
              if (
                this.params.type == 1 &&
                this.params.detailUpdateInputs.length > 1
              ) {
                this.$message.warning(
                  '转换方式一对一最多只能添加一条现有物品清单'
                )
                return
              }
              if (this.params.detailUpdateInputs.length < 1) {
                this.$message.warning('请添加转换物品清单')
                return
              }
              this.loading = true
              clearInterval(this.timeSaveFlag)
              await this.$api.apply.convertSave(newParams)
              //提交成功
              this.submitSuccess('/#/convertApply')
            } finally {
              this.loading = false
            }
          }
        })
      }
    },
    //申请人改变
    proposerChange(a) {
      this.$nextTick(() => {
        this.$refs.SelectDepartment?.changeEvent(a.cdepCode)
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
